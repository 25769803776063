<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.liftParameters')"
    id="liftParams"
    :class="{ mobileCss: !isMobile }"
  >
    <div id="outer-title">{{ $t("i18n.liftParameters") }}</div>
    <el-form :inline="true" :model="eleData" class="demo-form-inline">
      <el-form-item :label="$t('i18n.elevatorNo') + '：'">
        <span class="blueColor">{{ eleData.number }}</span>
      </el-form-item>
      <el-form-item :label="$t('i18n.projectName') + '：'">
        <span class="blueColor">{{ eleData.projectName }}</span>
      </el-form-item>
      <el-form-item :label="$t('i18n.controllerType') + '：'">
        <span class="blueColor">{{ eleData.controllerType }}</span>
      </el-form-item>
    </el-form>
    <div class="flex" v-if="tagsArray.length > 0">
      <ul class="left">
        <li
          :class="{ active: index == activeIndex }"
          v-for="(item, index) in tagsArray"
          :key="index"
          @click="sendMessageToAPP(item, index)"
        >
          {{ item.describe }}
        </li>
      </ul>
      <el-table
        :data="tagsArray[activeIndex].ctrlDataProtocolValues"
        border
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="functionCode"
          :label="$t('i18n.functionCode')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          :label="$t('i18n.description')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="webSocValue"
          :label="$t('i18n.value')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="referenceRange"
          :label="$t('i18n.referenceRange')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="defaultValue"
          :label="$t('i18n.factoryDefault')"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import socket from "../../../public/js/socket/index2";
import DataParsing from "../../../public/js/websocket/dataparsing.js";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "LiftParams",
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const dataParsing = new DataParsing();
    const state = reactive({
      eleData: {},
      isMobile: route.query.mobile || false,
      tagsArray: [],
      activeIndex: "0",
    });
    emit("public_header", !state.isMobile);
    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.getElevatorDetail(id);
      state.eleData = data;
      buildFunctionCodes();
    };

    const buildFunctionCodes = () => {
      let controllerType = state.eleData.controllerType;
      var tags = dataParsing.protocol.getProtocolTags(controllerType);
      if (!tags || tags.length === 0) {
        return;
      }
      //排除不是functioncode的tag  TdSerialTag.FunctionCode  功能码10+
      state.tagsArray = tags.filter((item) => item.value >= 10);
      state.tagsArray[state.activeIndex].ctrlDataProtocolValues.map((item) => {
        item.webSocValue = "";
      });
      socket.set(
        state.eleData,
        (ele) => {
          dealData(ele);
        },
        "tag"
      );
      sendMessageToAPP(state.tagsArray[0], 0);
    };

    const sendMessageToAPP = (item, index) => {
      state.activeIndex = index;
      var tagValue = item.value * 1;
      var samplingPeriod = dataParsing.protocol.getSamplingPeriod(
        state.eleData.controllerType,
        tagValue
      );
      var tags = [{ value: tagValue, samplingPeriod: samplingPeriod }];
      socket.sendMessage(tags);
    };

    init();

    const dealData = (data) => {
      data.map((item, i) => {
        var valueStr = "",
          unitStr = "";
        if (item.transferMethod == 3 || item.transferMethod == 6) {
          valueStr =
            item.escapeValue +
            ": " +
            (item.value || item.value == 0 ? item.value : "");
        } else {
          valueStr = item.value || item.value == 0 ? item.value : "";
        }
        unitStr = item.unit ? "(" + item.unit + ")" : "";
        let current =
          state.tagsArray[state.activeIndex].ctrlDataProtocolValues[i];
        if (current && current.functionCode == item.functionCode) {
          current.webSocValue = valueStr + unitStr;
        }
      });
    };

    return {
      ...toRefs(state),
      sendMessageToAPP,
    };
  },
};
</script>

<style lang="scss">
#liftParams {
  .blueColor {
    color: $blue_color;
    margin-right: 20px;
  }
  ul li {
    cursor: pointer;
    margin: 5px 0;
    padding-right: 30px;
    white-space: nowrap;
  }
}
@media screen and (max-width: 700px) {
  #liftParams {
    padding: 10px;
    margin: 0;
    .flex {
      display: block;
      ul li {
        display: inline-block;
        width: 33%;
        padding: 0;
      }
    }
  }
  #liftParams.mobileCss {
    margin: 0 5px;
  }
}
</style>
